import { defineComponent, reactive, computed, ref } from 'vue';
import Modal from '@components/Core/Modal.vue';
import Rating from '@modules/Rating/Rating.vue';
import Feedback from '@modules/Rating/Feedback.vue';
import HorizontalSeparator from '@components/Core/HorizontalSeparator.vue';
import { validateTranslations } from "@ts/Util/i18n";
import { interpolate } from "@ts/Util/text";
import PayPalTipHandler from "@components/Buy/PayPalTipHandler.vue";
import { DEFAULT_CURRENCY, paypalSupportedCurrencies } from "@ts/Util/currency";
import TextArea from "@components/Core/TextArea.vue";
import ZButton from "@components/Core/ZButton.vue";
import { useSafariMobileKeyboard } from "@composables/safariMobileKeyboard";
import Loading from '@components/Util/Loading.vue';
const localT = {
    modalTitle: 'How was your class with {0}?',
    feedbackHeader: 'What could have been better?',
    whatHappened: 'Tell us what happened...',
    submitFeedback: 'Submit Feedback',
    didNotAttendThisClass: 'I did not attend this class',
    at: 'at',
    tipTitle: 'Send tip to',
    tipSubtitle: 'Show some love to your instructor by sending a tip',
    tipAmountPlaceholder: 'Enter tip amount',
    sendTip: 'Send tip',
    cancel: 'Cancel',
    leaveOptionalReview: 'Leave a Review (Optional)',
    reviewBody: 'Share a review about this instructor.',
    reviewPlaceHolder: 'How was their class, their coaching style?',
    submit: 'Submit',
};
export default defineComponent({
    name: 'RateClass',
    components: {
        ZButton,
        TextArea,
        Modal,
        Rating,
        Feedback,
        HorizontalSeparator,
        PayPalTipHandler,
        Loading
    },
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: (value) => validateTranslations(value, localT),
        },
        instructorName: {
            type: String,
            required: true
        },
        instructorEmail: {
            type: String,
            required: true
        },
        /**
         * Class date object. This component is not responsible for determining
         * the type of  class and displaying the time based on it.
         */
        classDate: {
            type: Object,
            required: true,
        },
        show: {
            type: Boolean,
            default: false,
        },
        feedbackLabelsOptions: {
            type: Array,
            required: true,
        },
        tippingEnabled: {
            type: Boolean,
            required: true,
        },
        currencies: {
            type: Array,
            default: () => paypalSupportedCurrencies
        },
        currency: {
            type: String,
            default: DEFAULT_CURRENCY
        },
        instructorPid: {
            type: String,
            default: '',
        },
        storeTipUrl: {
            type: String,
            default: ''
        },
        markTipUrl: {
            type: String,
            default: ''
        },
        modalMode: {
            type: String,
            default: 'rate'
        },
        loading: {
            type: Boolean,
            default: false
        },
        enableDidnotAttend: {
            type: Boolean,
            default: true
        },
        giftSource: {
            type: String,
            default: 'post-class'
        },
        tipDataBaseAction: {
            type: String,
            default: ''
        },
        asyncContactRetrieval: {
            type: Boolean,
            default: true
        }
    },
    emits: [
        'close',
        'rating:rated',
        'did-not-attend',
        'feedback:submitted',
        'modal:close',
        'tip:send',
        'modal-mode:change'
    ],
    setup(props, ctx) {
        const state = reactive({
            displayModal: ref(props.show),
            rating: 0,
            feedback: {},
            positiveComment: '',
            submitting: false,
        });
        const modalTitle = computed(() => {
            return interpolate(props.t.modalTitle, [props.instructorName]);
        });
        const onRatingHandler = (rate) => {
            state.rating = rate;
            ctx.emit('rating:rated', rate);
        };
        const onNegativeFeedback = (feedback) => {
            state.feedback = feedback;
            ctx.emit('feedback:submitted', {
                rating: state.rating,
                feedback,
                closeModal: true,
            });
        };
        const onPositiveFeedback = () => {
            const showTip = Boolean(props.instructorEmail) &&
                props.tippingEnabled;
            ctx.emit('feedback:submitted', {
                rating: state.rating,
                feedback: {
                    comment: state.positiveComment,
                    selectedFeedbackLabels: {},
                },
                closeModal: !showTip,
            });
            if (showTip) {
                ctx.emit('modal-mode:change', 'tip');
                return;
            }
        };
        const onCloseRatingModal = () => {
            ctx.emit('modal:close', state.rating);
        };
        const { scrollTextIntoView } = useSafariMobileKeyboard();
        return {
            state,
            modalTitle,
            onRatingHandler,
            onNegativeFeedback,
            onPositiveFeedback,
            onCloseRatingModal,
            scrollTextIntoView,
        };
    },
    computed: {
        huminizedClassDate() {
            if (!this.classDate) {
                return '';
            }
            // request a weekday along with a long date
            const dateOptions = {
                weekday: 'long',
                month: 'long',
                day: 'numeric',
            };
            const timeOptions = {
                hour: 'numeric',
                minute: 'numeric',
                hour12: true,
            };
            const dateFormatted = new Intl.DateTimeFormat(window.navigator.language, dateOptions)
                .format(this.classDate);
            const timeFormatted = new Intl.DateTimeFormat(window.navigator.language, timeOptions)
                .format(this.classDate);
            return `${dateFormatted} ${this.t.at} ${timeFormatted}`;
        },
    },
    watch: {
        show: function (newVal) {
            this.state.displayModal = newVal;
            if (newVal === false) {
                this.state.rating = 0;
                this.state.feedback = {};
            }
        }
    },
});
