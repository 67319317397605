import { defineComponent, reactive, computed } from 'vue';
import FormCheckPillGroup from '@components/Core/FormCheckPillGroup.vue';
import TextArea from "@components/Core/TextArea.vue";
import ZButton from "@components/Core/ZButton.vue";
import { validateTranslations } from "@ts/Util/i18n";
const localT = {
    feedbackHeader: 'What could they do better?',
    whatHappened: 'Tell us what happened',
    submitFeedback: 'Submit Feedback',
};
const SOMETHING_ELSE_FEEDBACK_LABEL_SLUG = 'something-else';
export default defineComponent({
    name: 'Feedback',
    components: {
        FormCheckPillGroup,
        TextArea,
        ZButton
    },
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: (value) => validateTranslations(value, localT),
        },
        feedbackLabelsOptions: {
            type: Array,
            required: true,
        },
        comment: {
            type: String,
            default: ''
        }
    },
    emits: [
        'feedback:submitted',
    ],
    setup(props, ctx) {
        const state = reactive({
            selectedFeedbackLabels: {},
            comment: '',
        });
        props.feedbackLabelsOptions.forEach((feedbackLabelOption) => {
            state.selectedFeedbackLabels[feedbackLabelOption.value] =
                Boolean(feedbackLabelOption.hasOwnProperty('checked') && feedbackLabelOption.checked);
        });
        const onFeedbackSubmittedHandler = () => {
            ctx.emit('feedback:submitted', state);
        };
        const onSelectedFeedbackLabelHandler = (feedbackLabel, checked) => {
            state.selectedFeedbackLabels[feedbackLabel] = checked;
        };
        const isSomethingElseFeedbackLabelSelected = computed(() => {
            return Object.keys(state.selectedFeedbackLabels).includes(SOMETHING_ELSE_FEEDBACK_LABEL_SLUG)
                && state.selectedFeedbackLabels[SOMETHING_ELSE_FEEDBACK_LABEL_SLUG];
        });
        return {
            state,
            onFeedbackSubmittedHandler,
            onSelectedFeedbackLabelHandler,
            isSomethingElseFeedbackLabelSelected
        };
    },
});
