import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeClass as _normalizeClass } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_FormCheckPill = _resolveComponent("FormCheckPill");
    return (_openBlock(), _createElementBlock("div", {
        class: _normalizeClass([
            'options',
            _ctx.classes,
            { 'centered': _ctx.centered },
        ])
    }, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
            return (_openBlock(), _createElementBlock("div", {
                key: index,
                class: "option"
            }, [
                _createVNode(_component_FormCheckPill, {
                    modelValue: _ctx.checked[item.value],
                    "onUpdate:modelValue": ($event) => ((_ctx.checked[item.value]) = $event),
                    name: item.name || _ctx.name,
                    label: item.label,
                    value: item.value,
                    disabled: item.disabled || _ctx.disabled,
                    "full-width": _ctx.fillSpace,
                    onChange: _ctx.onLastChanged
                }, null, 8, ["modelValue", "onUpdate:modelValue", "name", "label", "value", "disabled", "full-width", "onChange"])
            ]));
        }), 128))
    ], 2));
}
