import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = ["for"];
const _hoisted_2 = ["id", "name", "value", "disabled"];
const _hoisted_3 = { class: "label" };
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    return (_openBlock(), _createElementBlock("label", {
        class: _normalizeClass(_ctx.classes),
        for: _ctx.id
    }, [
        _withDirectives(_createElementVNode("input", {
            id: _ctx.id,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.isChecked) = $event)),
            name: _ctx.name,
            value: _ctx.value,
            type: "checkbox",
            disabled: _ctx.disabled,
            class: "input",
            onClick: _cache[1] || (_cache[1] = ($event) => (_ctx.isFocused = false)),
            onFocus: _cache[2] || (_cache[2] = ($event) => (_ctx.isFocused = true)),
            onBlur: _cache[3] || (_cache[3] = ($event) => (_ctx.isFocused = false))
        }, null, 40, _hoisted_2), [
            [_vModelCheckbox, _ctx.isChecked]
        ]),
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.label), 1)
    ], 10, _hoisted_1));
}
