import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_RateClass = _resolveComponent("RateClass");
    const _component_toast_message = _resolveComponent("toast-message");
    return (_openBlock(), _createElementBlock("div", null, [
        _createVNode(_component_RateClass, {
            rating: _ctx.state.rating,
            "onUpdate:rating": _cache[0] || (_cache[0] = ($event) => ((_ctx.state.rating) = $event)),
            t: _ctx.t,
            show: _ctx.state.show,
            "instructor-name": _ctx.state.instructorName,
            "instructor-email": _ctx.state.instructorEmail,
            "class-date": _ctx.state.classDate,
            "tip-options": _ctx.state.tipAmounts,
            "feedback-labels-options": _ctx.state.feedbackOptions,
            "tipping-enabled": _ctx.state.tippingEnabled,
            currency: _ctx.defaultCurrency,
            currencies: _ctx.currencies,
            "instructor-pid": _ctx.state.instructorPID,
            "store-tip-url": _ctx.storeTipUrl,
            "mark-tip-url": _ctx.markTipUrl,
            "modal-mode": _ctx.state.modalMode,
            loading: _ctx.state.loading,
            "enable-didnot-attend": _ctx.enableDidnotAttend,
            "gift-source": _ctx.giftSource,
            "async-contact-retrieval": _ctx.asyncContactRetrieval,
            "tip-data-base-action": _ctx.tipDataBaseAction,
            onDidNotAttend: _ctx.onDidNotAttendHandler,
            "onRating:rated": _cache[1] || (_cache[1] = ($event) => (_ctx.onRatingRatedHandler($event))),
            "onFeedback:submitted": _cache[2] || (_cache[2] = ($event) => (_ctx.onFeedbackSubmittedHandler($event))),
            "onModal:close": _cache[3] || (_cache[3] = ($event) => (_ctx.onModalCloseHandler($event))),
            "onTip:send": _ctx.onTipPayed,
            "onModalMode:change": _ctx.onModalModeChange
        }, null, 8, ["rating", "t", "show", "instructor-name", "instructor-email", "class-date", "tip-options", "feedback-labels-options", "tipping-enabled", "currency", "currencies", "instructor-pid", "store-tip-url", "mark-tip-url", "modal-mode", "loading", "enable-didnot-attend", "gift-source", "async-contact-retrieval", "tip-data-base-action", "onDidNotAttend", "onTip:send", "onModalMode:change"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.toastState.queue, (toast, i) => {
            return (_openBlock(), _createBlock(_component_toast_message, {
                key: i,
                type: toast.type,
                message: toast.message,
                timeout: 3000,
                "user-dismissable": false,
                "is-visible": Boolean(toast.message),
                "stack-number": i,
                "top-left": ""
            }, null, 8, ["type", "message", "is-visible", "stack-number"]));
        }), 128))
    ]));
}
