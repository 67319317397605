import { computed, defineComponent, reactive, ref, toRaw } from 'vue';
import { currentUserLocale, validateTranslations } from "@ts/Util/i18n";
import RateClass from "@modules/Rating/RateClass.vue";
import Toast from "@components/Core/Toast.vue";
import { get } from "@ts/Util/http";
import { csrfToken } from "@ts/Util/security";
import { DEFAULT_CURRENCY, paypalCountryToCurrency, paypalSupportedCurrencies } from "@ts/Util/currency";
const localT = {
    errorSavingRating: 'Error occurred while saving the rating',
    feedbackHeader: 'What could have been better?',
    modalTitle: 'How was your experience with {0}?',
    ratingSubmitted: 'Thanks! Rating submitted.',
    whatHappened: 'Tell us what happened...',
    submitFeedback: 'Submit Feedback',
    leaveOptionalReview: 'Leave a review (Optional)',
    reviewBody: 'Share a review about this instructor.',
    reviewPlaceHolder: 'How was their class, their coaching style?',
    ratingAndTipSent: 'Your rating and tip have been sent!',
    submit: 'Submit',
    cancel: 'Cancel',
};
export var RatingStatus;
(function (RatingStatus) {
    RatingStatus["PENDING"] = "Pending";
    RatingStatus["RATED"] = "Rated";
    RatingStatus["MISSED"] = "Missed";
})(RatingStatus || (RatingStatus = {}));
export default defineComponent({
    name: 'RatingHandler',
    components: {
        RateClass,
        'toast-message': Toast,
    },
    props: {
        t: {
            type: Object,
            default: () => localT,
            validator: (value) => validateTranslations(value, localT),
        },
        ratingHandlerInfo: {
            type: Object,
            default: () => ({
                registrationId: null,
                modalMode: 'rate'
            }),
        },
        tippingCountry: {
            type: String,
            required: true
        },
        useMostRecentAttendedRegistration: {
            type: Boolean,
            default: false
        },
        feedbackLabelsAction: {
            type: String,
            default: '/ratings/feedbackLabels'
        },
        registrationDetailsAction: {
            type: String,
            default: '/ratings/registrationDetails'
        },
        studentMostRecentRatePendingRegistrationAction: {
            type: String,
            default: '/ratings/studentMostRecentRatePendingRegistration'
        },
        saveRatingAction: {
            type: String,
            default: '/ratings/save'
        },
        currencies: {
            type: Array,
            default: () => paypalSupportedCurrencies
        },
        dismissRatingAction: {
            type: String,
            default: '/ratings/dismiss'
        },
        storeTipUrl: {
            type: String,
            default: ''
        },
        markTipUrl: {
            type: String,
            default: ''
        },
        enableDidnotAttend: {
            type: Boolean,
            default: true
        },
        giftSource: {
            type: String,
            default: ''
        },
        tipDataBaseAction: {
            type: String,
            default: ''
        },
        asyncContactRetrieval: {
            type: Boolean,
            default: true
        }
    },
    emits: [],
    setup(props) {
        const toastState = reactive({
            queue: Array()
        });
        let tipAmounts = [1, 3, 5, 10];
        let feedbackOptions = [];
        const locale = currentUserLocale();
        const dismissPreviouslyAttended = ref(false);
        const registrationID = ref(props.ratingHandlerInfo?.registrationId);
        const isFetchingFeedbackLabels = ref(true);
        const isFetchingRegistrationDetails = ref(Boolean(props.ratingHandlerInfo?.registrationId));
        const showRatingModal = computed(() => Boolean(registrationID.value
            && !isFetchingRegistrationDetails.value
            && !isFetchingFeedbackLabels.value));
        const fetchFeedbackLabelOptions = async () => {
            try {
                const response = await get(`/${locale}${props.feedbackLabelsAction}`, csrfToken());
                const data = await response.json();
                isFetchingFeedbackLabels.value = false;
                return data;
            }
            catch (error) {
                console.warn('An error occurred while fetching ratings feedback labels data');
                return null;
            }
        };
        const fetchStudentMostRecentRatePendingRegistration = async () => {
            try {
                const response = await get(`${locale}${props.studentMostRecentRatePendingRegistrationAction}`, csrfToken());
                const data = await response.json();
                return data;
            }
            catch (error) {
                console.warn('An error occurred while fetching student rate pending registration data');
                return null;
            }
        };
        const fetchRegistrationsDetails = async () => {
            if (registrationID.value) {
                try {
                    const response = await get(`/${locale}${props.registrationDetailsAction}/${registrationID.value}`, csrfToken());
                    const data = await response.json();
                    isFetchingRegistrationDetails.value = false;
                    return data;
                }
                catch (error) {
                    console.warn('An error occurred while fetching registration data');
                }
            }
        };
        const defaultCurrency = paypalCountryToCurrency[props.tippingCountry] || DEFAULT_CURRENCY;
        const state = reactive({
            rating: 0,
            tipAmounts,
            feedbackOptions,
            instructorName: '',
            instructorEmail: '',
            show: showRatingModal,
            instructorPID: '',
            registrationRatingStatus: null,
            classDate: new Date(),
            tippingEnabled: false,
            modalMode: 'rate',
            loading: false,
            tipWasAdded: false
        });
        if (registrationID.value) {
            fetchRegistrationsDetails().then((detailsResponse) => {
                if (detailsResponse) {
                    state.instructorName = 'instructor' in detailsResponse ?
                        `${detailsResponse['instructor']['display_name']}` : '';
                    state.instructorEmail = 'instructor_paypal_email' in detailsResponse ?
                        `${detailsResponse['instructor_paypal_email']}` : '';
                    state.registrationRatingStatus = 'rating_status' in detailsResponse ?
                        detailsResponse['rating_status'] : null;
                    // determine what date to display based on the class type
                    state.classDate = fetchClassDate(detailsResponse);
                    state.tippingEnabled = detailsResponse.instructor_allow_reviewers_tip;
                    state.instructorPID = 'instructor' in detailsResponse ?
                        `${detailsResponse['instructor']['pid']}` : '';
                    fetchFeedbackLabelOptions().then((optionsResponse) => {
                        state.feedbackOptions = typeof optionsResponse == 'object'
                            ? optionsResponse?.[detailsResponse['class']['platform_type']] : [];
                    });
                }
            });
        }
        else if (props.useMostRecentAttendedRegistration) {
            dismissPreviouslyAttended.value = true;
            fetchStudentMostRecentRatePendingRegistration().then((response) => {
                if (response && 'registration_id' in response) {
                    isFetchingRegistrationDetails.value = true;
                    registrationID.value = response.registration_id;
                    fetchRegistrationsDetails().then((detailsResponse) => {
                        if (detailsResponse) {
                            state.instructorName = 'instructor' in detailsResponse ?
                                `${detailsResponse['instructor']['display_name']}` : '';
                            state.instructorEmail = 'instructor_paypal_email' in detailsResponse ?
                                `${detailsResponse['instructor_paypal_email']}` : '';
                            state.registrationRatingStatus = 'rating_status' in detailsResponse ?
                                detailsResponse['rating_status'] : null;
                            // determine what date to display based on the class type
                            state.classDate = fetchClassDate(detailsResponse);
                            state.tippingEnabled = detailsResponse.instructor_allow_reviewers_tip;
                            state.instructorPID = 'instructor' in detailsResponse ?
                                `${detailsResponse['instructor']['pid']}` : '';
                            fetchFeedbackLabelOptions().then((optionsResponse) => {
                                state.feedbackOptions = typeof optionsResponse == 'object'
                                    ? optionsResponse?.[detailsResponse['class']['platform_type']] : [];
                            });
                        }
                    });
                }
            });
        }
        const fetchClassDate = (classRegistration) => {
            if (classRegistration['class']['platform_type'] === 'VOD') {
                return new Date(classRegistration['calculated_attendance']);
            }
            else if (classRegistration['class']['platform_type'] === 'In Person') {
                return new Date(classRegistration['class']['effective_date']);
            }
            return new Date(classRegistration['class']['effective_date_utc']);
        };
        const onRatingRatedHandler = async (rate) => {
            const csrf = await csrfToken();
            const formData = new FormData();
            formData.append('registrationID', registrationID.value);
            formData.append('rating', rate.toString());
            formData.append('dismissPreviouslyAttended', dismissPreviouslyAttended.value.toString());
            const fetchConfig = {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': csrf
                },
                body: formData
            };
            try {
                const response = await fetch(`/${locale}${props.saveRatingAction}`, fetchConfig);
            }
            catch (error) {
                console.error('Error, could not save rating.', error);
            }
        };
        async function dismiss(regID, didNotAttend) {
            const csrf = await csrfToken();
            const formData = new FormData();
            formData.append('registrationID', regID);
            formData.append('dismissPreviouslyAttended', dismissPreviouslyAttended.value.toString());
            formData.append('didNotAttend', didNotAttend ? '1' : '0');
            const fetchConfig = {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': csrf
                },
                body: formData
            };
            try {
                const response = await fetch(`/${locale}${props.dismissRatingAction}`, fetchConfig);
            }
            catch (error) {
                console.error('Error, could not dismiss rating.', error);
            }
        }
        const onModalCloseHandler = async (rating) => {
            let regID = registrationID.value;
            registrationID.value = null;
            if (rating == 0 && state.registrationRatingStatus !== RatingStatus.MISSED) {
                await dismiss(regID, false);
            }
            else {
                toastState.queue.push({
                    type: 'success',
                    message: state.tipWasAdded ? props.t.ratingAndTipSent : props.t.ratingSubmitted
                });
            }
        };
        const onDidNotAttendHandler = async () => {
            let regID = registrationID.value;
            registrationID.value = null;
            dismiss(regID, true);
        };
        const showSuccessToast = () => {
            toastState.queue.push({
                type: 'success',
                message: state.tipWasAdded ? props.t.ratingAndTipSent : props.t.ratingSubmitted,
            });
        };
        const onFeedbackSubmittedHandler = async (ratingData) => {
            let regID = registrationID.value;
            if (ratingData.closeModal) {
                registrationID.value = null;
            }
            const csrf = await csrfToken();
            const formData = new FormData();
            formData.append('registrationID', regID);
            formData.append('rating', ratingData.rating.toString());
            formData.append('dismissPreviouslyAttended', dismissPreviouslyAttended.value.toString());
            if ('feedback' in ratingData) {
                formData.append('comment', ratingData.feedback.comment);
                const rawFeedbackLabels = toRaw(ratingData.feedback.selectedFeedbackLabels);
                for (const key in rawFeedbackLabels) {
                    if (Object.prototype.hasOwnProperty.call(rawFeedbackLabels, key) &&
                        rawFeedbackLabels[key]) {
                        formData.append('feedbackLabels[]', key);
                    }
                }
                // Force allow comments on 4 or 5 star ratings when comment is provided.
                if (ratingData.rating >= 4 && ratingData.rating <= 5 && Boolean(ratingData.feedback.comment)) {
                    formData.append('alwaysAllowComment', '1');
                }
            }
            const fetchConfig = {
                method: 'POST',
                headers: {
                    'X-Requested-With': 'XMLHttpRequest',
                    'X-CSRF-Token': csrf
                },
                body: formData
            };
            state.loading = true;
            try {
                const response = await fetch(`/${locale}${props.saveRatingAction}`, fetchConfig);
                state.loading = false;
                if (!response.ok) {
                    toastState.queue.push({
                        type: 'danger',
                        message: props.t.errorSavingRating
                    });
                    return;
                }
                else if (ratingData.closeModal) {
                    Object.assign(state.show, false);
                    showSuccessToast();
                }
            }
            catch (error) {
                state.loading = false;
                console.error('Error, could not save rating.', error);
            }
        };
        const onTipPayed = () => {
            state.tipWasAdded = true;
        };
        const onModalModeChange = (modalMode) => {
            state.modalMode = modalMode;
        };
        return {
            state,
            toastState,
            registrationID,
            dismissPreviouslyAttended,
            isFetchingRegistrationDetails,
            fetchClassDate,
            defaultCurrency,
            fetchFeedbackLabelOptions,
            fetchRegistrationsDetails,
            fetchStudentMostRecentRatePendingRegistration,
            onRatingRatedHandler,
            onFeedbackSubmittedHandler,
            onDidNotAttendHandler,
            onModalCloseHandler,
            onTipPayed,
            onModalModeChange
        };
    },
    watch: {
        ratingHandlerInfo: function (newVal) {
            this.state.modalMode = newVal?.modalMode;
            const registrationId = newVal?.registrationId;
            if (registrationId) {
                this.isFetchingRegistrationDetails = true;
                this.dismissPreviouslyAttended = false;
                this.registrationID = registrationId;
                this.fetchRegistrationsDetails().then((detailsResponse) => {
                    if (detailsResponse) {
                        this.state.instructorName = 'instructor' in detailsResponse ?
                            `${detailsResponse['instructor']['display_name']}` : '';
                        this.state.instructorEmail = 'instructor_paypal_email' in detailsResponse ?
                            `${detailsResponse['instructor_paypal_email']}` : '';
                        this.state.registrationRatingStatus = 'rating_status' in detailsResponse ?
                            detailsResponse['rating_status'] : null;
                        this.state.classDate = this.fetchClassDate(detailsResponse);
                        this.state.tippingEnabled = detailsResponse.instructor_allow_reviewers_tip;
                        this.state.instructorPID = 'instructor' in detailsResponse ?
                            `${detailsResponse['instructor']['pid']}` : '';
                        this.fetchFeedbackLabelOptions().then((optionsResponse) => {
                            this.state.feedbackOptions = typeof optionsResponse == 'object'
                                ? optionsResponse?.[detailsResponse['class']['platform_type']] : [];
                        });
                    }
                });
            }
        },
        modalMode(newVal) {
            this.onModalModeChange(newVal);
        }
    },
});
