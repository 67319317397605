import { defineComponent } from 'vue';
import FormCheckPill from '@components/Core/FormCheckPill.vue';
export default defineComponent({
    name: 'FormCheckPillGroup',
    components: { FormCheckPill },
    props: {
        items: {
            type: Array,
            default: () => []
        },
        /**
         * Used by vue to bind the checkbox check state to `v-model` set on the component.
         */
        modelValue: {
            type: Object,
            default: () => {
                return {};
            }
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        name: {
            type: String,
            default: 'selected[]'
        },
        fillSpace: {
            type: Boolean,
            default: false
        },
        centered: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue', 'item-changed'],
    computed: {
        classes() {
            return {
                fill: this.fillSpace
            };
        },
        checked: {
            get() {
                return this.modelValue;
            },
            set(checked) {
                this.$emit('update:modelValue', checked);
            }
        }
    },
    methods: {
        onLastChanged(e) {
            const lastVal = e.target.value;
            const isChecked = e.target.checked;
            this.$emit('item-changed', lastVal, isChecked);
        }
    }
});
