import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = { class: "feedback-wrapper" };
const _hoisted_2 = ["innerHTML"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
    const _component_form_check_pill_group = _resolveComponent("form-check-pill-group");
    const _component_text_area = _resolveComponent("text-area");
    const _component_ZButton = _resolveComponent("ZButton");
    return (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("p", {
            class: "f-inter",
            innerHTML: _ctx.t.feedbackHeader
        }, null, 8, _hoisted_2),
        _createElementVNode("div", null, [
            _createVNode(_component_form_check_pill_group, {
                modelValue: _ctx.state.selectedFeedbackLabels,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event) => ((_ctx.state.selectedFeedbackLabels) = $event)),
                name: "selected_feedback_labels[]",
                class: _normalizeClass({ 'centered': true }),
                items: _ctx.feedbackLabelsOptions,
                onItemChanged: _ctx.onSelectedFeedbackLabelHandler
            }, null, 8, ["modelValue", "items", "onItemChanged"]),
            _cache[2] || (_cache[2] = _createElementVNode("div", { class: "separator" }, null, -1)),
            (_ctx.isSomethingElseFeedbackLabelSelected)
                ? (_openBlock(), _createBlock(_component_text_area, {
                    key: 0,
                    modelValue: _ctx.state.comment,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event) => ((_ctx.state.comment) = $event)),
                    name: "comment",
                    rows: 5,
                    label: _ctx.t.whatHappened,
                    maxlength: 200
                }, null, 8, ["modelValue", "label"]))
                : _createCommentVNode("", true),
            _cache[3] || (_cache[3] = _createElementVNode("div", { class: "separator" }, null, -1)),
            _createVNode(_component_ZButton, {
                primary: "",
                "full-width": "",
                class: "tip--custom-tip--button",
                onClick: _ctx.onFeedbackSubmittedHandler
            }, {
                default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.t.submitFeedback), 1)
                ]),
                _: 1
            }, 8, ["onClick"])
        ])
    ]));
}
