import { computed, defineComponent, ref } from 'vue';
import { v4 as uuid } from 'uuid';
export default defineComponent({
    name: 'FormCheckPill',
    props: {
        name: {
            type: String,
            required: true,
        },
        value: {
            type: String,
            default: '',
        },
        label: {
            type: String,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        /**
         * Used by vue to bind the checkbox check state to `v-model` set on the component.
         */
        modelValue: {
            type: Boolean,
            default: false,
        },
        fullWidth: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['update:modelValue'],
    setup(props, context) {
        const id = uuid();
        const isFocused = ref(false);
        const isChecked = computed({
            get() {
                return props.modelValue;
            },
            set(isChecked) {
                context.emit('update:modelValue', isChecked);
            }
        });
        const classes = computed(() => {
            return {
                'outer-label': true,
                checked: isChecked.value,
                disabled: props.disabled,
                focused: isFocused.value,
                'full-width': props.fullWidth,
            };
        });
        return {
            id,
            isChecked,
            isFocused,
            classes,
        };
    },
});
